<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="资源编码">
            <el-input clearable v-model="searchForm.code" placeholder="请输入 资源编码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="数据类型">
            <el-select clearable v-model="searchForm.type" placeholder="请选择" size="large">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </template>
    </Searchbar>
    <basicTable
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :total="page.total">
    </basicTable>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue';
import { getData } from "@/api/devops/info";
import Searchbar from '@/components/Searchbar';
import { downloadTar } from "../../api/devops/control";
export default {
  components:{
    Searchbar
  },
  setup(){
    let state = reactive({
      options:[
        {
          value:"EBM",
          label:"应急广播消息"
        },
        {
          value:"EBI",
          label:"应急广播信息"
        },
        {
          value:"EBMStateRequest",
          label:"应急广播消息播发状态查询"
        },{
          value:"EBMStateResponse",
          label:"应急广播消息播发状态反馈"
        },
        {
          value:"EBRPSInfo",
          label:"应急广播平台信息"
        },
        {
          value:"EBRSTInfo",
          label:"为台站（前端）信息"
        },
        {
          value:"EBRASInfo",
          label:"应急广播适配器信息"
        },
        {
          value:"EBRBSInfo",
          label:"传输覆盖播出设备信息"
        },
        {
          value:"EBRDTInfo",
          label:"平台设备及终端信息"
        },
        {
          value:"EBRPSState",
          label:"应急广播平台状态"
        },
        {
          value:"EBRASState",
          label:"应急广播适配器状态"
        },
        {
          value:"EBRBSState",
          label:"传输覆盖播出设备状态"
        },
        {
          value:"EBRDTState",
          label:"平台设备及终端状态"
        },
        {
          value:"EBMBrdLog",
          label:"播出记录"
        },
        {
          value:"ConnectionCheck",
          label:"心跳"
        },
        {
          value:"EBDNotify",
          label:"处理结果通知"
        },
        {
          value:"EBDResponse",
          label:"接收回执文件"
        },
        {
          value:"OMDRequest",
          label:"运维数据请求"
        },
      ],
      searchForm:{},
      tableData:[],
      attrList:[
        {
          prop: "sourceName",
          label: "平台系统名称",
          render: (row) => <div>{row.sourceName}</div>,
        },
        {
          prop: "receiveEbdId",
          label: "数据包ID",
          render: (row) => <div>{row.receiveEbdId}</div>,
        },
        {
          prop: "snedEbdId",
          label: "数据包编码",
          render: (row) => <div>{row.sendEbdId}</div>,
        },
        {
          prop: "receiveDesc",
          label: "平台响应",
          render: (row) => <div>{row.receiveDesc}</div>,
        },
        {
          prop: "sendDesc",
          label: "发送后响应",
          render: (row) => <div>{row.sendDesc}</div>,
        },
        {
          prop: "type",
          label: "数据类型",
          render: (row) => <div>{row.type}</div>,
        },
        {
          prop: "typeName",
          label: "类型名称",
          render: (row) => <div>{row.typeName}</div>,
        },
        {
          prop: "receiveTime",
          label: "接受包时间",
          render: (row) => <div>{row.receiveTime}</div>,
        },
        {
          prop: "sendTime",
          label: "发送包时间",
          render: (row) => <div>{row.sendTime}</div>,
        },
        {
          prop: "",
          label: "下载发包",
          render: (row) =>row.sendEbdId ? <el-button type="text" icon="Download" onClick={()=>download(row)}>下载</el-button> : <span></span>
        },
        {
          prop: "",
          label: "下载收包",
          render: (row) => row.receiveEbdId ? <el-button type="text" icon="Download" onClick={() => shoubao(row)}>下载</el-button> : <span></span>,
        }
      ],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    })
    onMounted(()=>{
      onLoad();
    })
    function onLoad(form = {}){
      getData({ limit:state.page.limit, page:state.page.current, ...form }).then(res=>{
        state.tableData = res.data.data.list;
        state.page.total = res.data.data.count;
      })
    }

    function shoubao(row){
      downloadTar({ type:2, ebdId:row.receiveEbdId }).then(res=>{
        const blob = new Blob([res.data], {
          type: "application/x-tar"
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "数据包.tar");
        link.click();
        link = null;
      })
    }

    function download(row){
      downloadTar({ type:1, ebdId:row.sendEbdId }).then(res=>{
        const blob = new Blob([res.data], {
          type: "application/x-tar"
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "数据包.tar");
        link.click();
        link = null;
      })
    }

    function onSearch(){
      onLoad(state.searchForm)
    }

    function handleCurrentChange(currentPage) {
      state.page.current = currentPage;
      onLoad(state.searchForm);
    }

    function handleSizeChange(pageSize) {
      state.page.limit = pageSize;
      onLoad(state.searchForm);
    }

    function resetForm() {
      state.searchForm = {};
      onLoad(state.searchForm);
    }

    return{
      handleCurrentChange,
      handleSizeChange,
      resetForm,
      onSearch,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>

</style>